.disclosure__list {
  margin-top: 0;
  min-height: 76px;
  max-height: 158px;
  max-width: 300px;
  min-width: 180px;
  width: max-content;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 13px 20px 20px;
  position: absolute;
  bottom: 100%;
  border-radius: var(--radius-input);
  transform: translateY(-10px);
  z-index: 2;
  --color-base: var(--color-input-text);
  background-color: var(--color-simple-dropdown);
  box-shadow: 0px 4px 20px rgba(22, 19, 69, 0.07);
}
.disclosure__list--to__bottom{
  bottom: unset;
  top: 100%;
  transform: translateY(10px);
  right: 0;
}
.disclosure__link {
  display: block;
  width: max-content;
  padding-top: 7px;
  cursor: pointer;
}
.localization-selector + .disclosure__list {
  opacity: 1;
  animation: animateLocalization var(--duration-short) ease;
}
.disclosure__list--to__bottom{
  opacity: 1;
  animation: animateSortBy var(--duration-short) ease;
}
@keyframes animateLocalization {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(-10px);
  }
}
@keyframes animateSortBy {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(10px);
  }
}
@media (max-width: 576px){
  .disclosure__list{
    width: 100%;
    max-width: 100%;
  }
}